function Logo() {
    return (
      <svg width="175" height="36" viewBox="0 0 175 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.4064 6.83529L28.9254 19.3975L16.8548 34.8505C16.627 35.1421 16.1858 35.1421 15.9579 34.8505L0.123717 14.5793C0.0396053 14.4716 0.00185286 14.3497 0.00207483 14.2306C0.000701006 14.2144 0 14.1981 0 14.1816V9.25025C0 9.08255 0.072546 8.93179 0.18797 8.82766C0.206819 8.80138 0.228361 8.77601 0.252708 8.75182L8.89268 0.165409C9.1146 -0.0551365 9.47295 -0.0551362 9.69487 0.165409L16.4064 6.83529ZM19.3109 30.4602H13.5011L14.8105 32.0541H18.0037L19.3109 30.4602Z" fill="#FF9900"/>
      <path d="M17.5496 5.70398L23.0149 0.233077C23.2332 0.014618 23.5856 0.0146183 23.8039 0.233077L32.301 8.73826C32.325 8.76223 32.3462 8.78736 32.3647 8.81338C32.4782 8.91654 32.5496 9.06586 32.5496 9.23197V14.1167C32.5496 14.133 32.5489 14.1492 32.5475 14.1652C32.5477 14.2831 32.5106 14.404 32.4279 14.5106L29.8272 18.0692L17.5496 5.70398Z" fill="url(#paint0_linear_15_14)"/>
      <path d="M125.077 29.948V5H140.125V7.988H128.569V15.512H138.829V18.464H128.569V29.948H125.077Z" fill="#FF9900"/>
      <path d="M158.069 20.588C158.069 22.076 157.853 23.42 157.421 24.62C156.989 25.82 156.377 26.852 155.585 27.716C154.817 28.58 153.893 29.252 152.813 29.732C151.733 30.188 150.557 30.416 149.285 30.416C148.013 30.416 146.837 30.188 145.757 29.732C144.677 29.252 143.741 28.58 142.949 27.716C142.181 26.852 141.581 25.82 141.149 24.62C140.717 23.42 140.501 22.076 140.501 20.588C140.501 19.124 140.717 17.792 141.149 16.592C141.581 15.368 142.181 14.324 142.949 13.46C143.741 12.596 144.677 11.936 145.757 11.48C146.837 11 148.013 10.76 149.285 10.76C150.557 10.76 151.733 11 152.813 11.48C153.893 11.936 154.817 12.596 155.585 13.46C156.377 14.324 156.989 15.368 157.421 16.592C157.853 17.792 158.069 19.124 158.069 20.588ZM154.577 20.588C154.577 18.476 154.097 16.808 153.137 15.584C152.201 14.336 150.917 13.712 149.285 13.712C147.653 13.712 146.357 14.336 145.397 15.584C144.461 16.808 143.993 18.476 143.993 20.588C143.993 22.7 144.461 24.38 145.397 25.628C146.357 26.852 147.653 27.464 149.285 27.464C150.917 27.464 152.201 26.852 153.137 25.628C154.097 24.38 154.577 22.7 154.577 20.588Z" fill="#FF9900"/>
      <path d="M171.218 29.948C170.954 29.42 170.63 28.832 170.246 28.184C169.862 27.536 169.442 26.876 168.986 26.204C168.53 25.508 168.062 24.824 167.582 24.152C167.102 23.48 166.634 22.844 166.178 22.244C165.722 22.844 165.254 23.492 164.774 24.188C164.294 24.86 163.826 25.544 163.37 26.24C162.938 26.912 162.53 27.572 162.146 28.22C161.762 28.868 161.438 29.444 161.174 29.948H157.718C158.558 28.316 159.554 26.66 160.706 24.98C161.882 23.276 163.046 21.656 164.198 20.12L158.006 11.228H161.786L166.25 17.78L170.75 11.228H174.314L168.266 19.904C169.418 21.464 170.594 23.12 171.794 24.872C173.018 26.6 174.05 28.292 174.89 29.948H171.218Z" fill="#FF9900"/>
      <path d="M57.7639 5.988V9.012H49.9159V30.936H46.4239V9.012H38.5759V5.988H57.7639Z" fill="#A7967C"/>
      <path d="M64.546 11.82C64.834 11.82 65.158 11.844 65.518 11.892C65.902 11.916 66.274 11.964 66.634 12.036C66.994 12.084 67.318 12.144 67.606 12.216C67.918 12.264 68.146 12.312 68.29 12.36L67.714 15.276C67.45 15.18 67.006 15.072 66.382 14.952C65.782 14.808 65.002 14.736 64.042 14.736C63.418 14.736 62.794 14.808 62.17 14.952C61.57 15.072 61.174 15.156 60.982 15.204V30.936H57.634V13.008C58.426 12.72 59.41 12.456 60.586 12.216C61.762 11.952 63.082 11.82 64.546 11.82Z" fill="#A7967C"/>
      <path d="M68.3167 21.612C68.3167 19.956 68.5567 18.516 69.0367 17.292C69.5167 16.044 70.1527 15.012 70.9447 14.196C71.7367 13.38 72.6487 12.768 73.6807 12.36C74.7127 11.952 75.7687 11.748 76.8487 11.748C79.3687 11.748 81.3007 12.54 82.6447 14.124C83.9887 15.684 84.6607 18.072 84.6607 21.288C84.6607 21.432 84.6607 21.624 84.6607 21.864C84.6607 22.08 84.6487 22.284 84.6247 22.476H71.8087C71.9527 24.42 72.5167 25.896 73.5007 26.904C74.4847 27.912 76.0207 28.416 78.1087 28.416C79.2847 28.416 80.2687 28.32 81.0607 28.128C81.8767 27.912 82.4887 27.708 82.8967 27.516L83.3647 30.324C82.9567 30.54 82.2367 30.768 81.2047 31.008C80.1967 31.248 79.0447 31.368 77.7487 31.368C76.1167 31.368 74.7007 31.128 73.5007 30.648C72.3247 30.144 71.3527 29.46 70.5847 28.596C69.8167 27.732 69.2407 26.712 68.8567 25.536C68.4967 24.336 68.3167 23.028 68.3167 21.612ZM81.1687 19.776C81.1927 18.264 80.8087 17.028 80.0167 16.068C79.2487 15.084 78.1807 14.592 76.8127 14.592C76.0447 14.592 75.3607 14.748 74.7607 15.06C74.1847 15.348 73.6927 15.732 73.2847 16.212C72.8767 16.692 72.5527 17.244 72.3127 17.868C72.0967 18.492 71.9527 19.128 71.8807 19.776H81.1687Z" fill="#A7967C"/>
      <path d="M87.3101 12.756C88.0781 12.564 89.0981 12.36 90.3701 12.144C91.6421 11.928 93.1061 11.82 94.7621 11.82C96.2501 11.82 97.4861 12.036 98.4701 12.468C99.4541 12.876 100.234 13.464 100.81 14.232C101.41 14.976 101.83 15.876 102.07 16.932C102.31 17.988 102.43 19.152 102.43 20.424V30.936H99.0821V21.144C99.0821 19.992 98.9981 19.008 98.8301 18.192C98.6861 17.376 98.4341 16.716 98.0741 16.212C97.7141 15.708 97.2341 15.348 96.6341 15.132C96.0341 14.892 95.2901 14.772 94.4021 14.772C94.0421 14.772 93.6701 14.784 93.2861 14.808C92.9021 14.832 92.5301 14.868 92.1701 14.916C91.8341 14.94 91.5221 14.976 91.2341 15.024C90.9701 15.072 90.7781 15.108 90.6581 15.132V30.936H87.3101V12.756Z" fill="#A7967C"/>
      <path d="M117.918 16.176C117.51 15.84 116.922 15.516 116.154 15.204C115.386 14.892 114.546 14.736 113.634 14.736C112.674 14.736 111.846 14.916 111.15 15.276C110.478 15.612 109.926 16.092 109.494 16.716C109.062 17.316 108.75 18.036 108.558 18.876C108.366 19.716 108.27 20.616 108.27 21.576C108.27 23.76 108.81 25.452 109.89 26.652C110.97 27.828 112.41 28.416 114.21 28.416C115.122 28.416 115.878 28.38 116.478 28.308C117.102 28.212 117.582 28.116 117.918 28.02V16.176ZM117.918 3.576L121.266 3V30.396C120.498 30.612 119.514 30.828 118.314 31.044C117.114 31.26 115.734 31.368 114.174 31.368C112.734 31.368 111.438 31.14 110.286 30.684C109.134 30.228 108.15 29.58 107.334 28.74C106.518 27.9 105.882 26.88 105.426 25.68C104.994 24.456 104.778 23.088 104.778 21.576C104.778 20.136 104.958 18.816 105.318 17.616C105.702 16.416 106.254 15.384 106.974 14.52C107.694 13.656 108.57 12.984 109.602 12.504C110.658 12.024 111.858 11.784 113.202 11.784C114.282 11.784 115.23 11.928 116.046 12.216C116.886 12.504 117.51 12.78 117.918 13.044V3.576Z" fill="#A7967C"/>
      <defs>
      <linearGradient id="paint0_linear_15_14" x1="30.4609" y1="2.44729" x2="24.9308" y2="7.81971" gradientUnits="userSpaceOnUse">
      <stop offset="0.0260417" stopColor="#FF9900"/>
      <stop offset="1" stopColor="#F19305"/>
      </linearGradient>
      </defs>
      </svg>
      )
}

export default Logo