import { Link, useLocation } from "react-router-dom";
import routes from "@/routes/routes";
function ReportsNavigation() {
  const location = useLocation();
  return (
    <>
      {routes
        .filter((r) => r.parent === "reports" && r.inGroupNav === true)
        .map((route) => (
          <Link
            key={route.path}
            to={route.path}
            className={`px-3 py-2 mx-2 mt-2 text-sm font-medium text-gray-700 transition-colors duration-200 transform rounded-md md:mt-0 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-800 ${
              location.pathname === route.path
                ? "bg-gray-300 dark:bg-gray-800"
                : ""
            }`}
          >
            {route.title}
          </Link>
        ))}
    </>
  );
}
export default ReportsNavigation;
