function SubNavigation({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className="sticky z-10 bg-gray-200 shadow dark:bg-gray-700">
      <div className="flex flex-col items-center px-6 py-3 mx-auto space-y-6 sm:flex-row sm:justify-between sm:space-y-0 ">
        <div className="-mx-2 text-gray-700 bg-gray-100 dark:text-white dark:bg-gray-700">
          {children}
        </div>
      </div>
    </div>
  );
}

export default SubNavigation;
