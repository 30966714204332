import Logo from "@/library/Logo";
import { state as authState, updateAuthState } from "@/store/auth";
import Alert from "@/library/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "@/store/auth";
import { useSnapshot } from "valtio";

function ResetPassword() {
  const { password, confirmPassword, errors } = useSnapshot(authState);
  const navigate = useNavigate();

  const { token } = useParams();
  if (errors.length) updateAuthState({ errors: [] });

  const canSubmit = () =>
    password && confirmPassword && password === confirmPassword;
  const updatePassword = (event: React.FormEvent<HTMLInputElement>) => {
    updateAuthState({ password: event.currentTarget.value });
  };
  const updateConfirmPassword = (event: React.FormEvent<HTMLInputElement>) => {
    updateAuthState({ confirmPassword: event.currentTarget.value });
  };

  const authorise = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!canSubmit()) return;
    await resetPassword(token!);
    navigate("/login");
  };

  return (
    <div className="flex-1">
      <div className="text-center">
        <div className="flex justify-center">
          <Logo />
        </div>

        <h1 className="mt-3 text-gray-500 dark:text-gray-300">
          Request password reset
        </h1>
      </div>

      <div className="mt-8">
        {errors
          ? errors.map((error: string) => <Alert key={error}> {error}</Alert>)
          : ""}
        <form>
          <div>
            <div className="flex justify-between mb-2">
              <label
                htmlFor="password"
                className="text-sm text-gray-600 dark:text-gray-200"
              >
                Password
              </label>
            </div>

            <input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={updatePassword}
              placeholder="Your Password"
              autoComplete="current-password"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mt-6">
            <div className="flex justify-between mb-2">
              <label
                htmlFor="confirmPassword"
                className="text-sm text-gray-600 dark:text-gray-200"
              >
                Confirm Password
              </label>
            </div>

            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              onChange={updateConfirmPassword}
              placeholder="Your Password"
              autoComplete="none"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mt-6">
            <button
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              onClick={authorise}
              disabled={!canSubmit()}
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
