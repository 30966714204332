import Logo from "../../../library/Logo";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Alert from "../../../library/Alert";
import {
  state as authState,
  updateAuthState,
  isAuthorised,
  login,
  watchToken,
} from "@/store/auth";
import { useSnapshot } from "valtio";
function Login() {
  const navigate = useNavigate();
  const { email, password, errors } = useSnapshot(authState);
  if (errors.length) updateAuthState({ errors: [] });

  const canSubmit = () => email && password;
  const updateEmail = (event: React.FormEvent<HTMLInputElement>) => {
    updateAuthState({ email: event.currentTarget.value });
  };
  const updatePassword = (event: React.FormEvent<HTMLInputElement>) => {
    updateAuthState({ password: event.currentTarget.value });
  };
  const authorise = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!canSubmit()) return;
    login();
  };
  useEffect(() =>
    watchToken((v: any) => {
      if (isAuthorised()) {
        navigate("/");
      }
    })
  );

  return (
    <div className="flex-1">
      <div className="text-center">
        <div className="flex justify-center">
          <Logo />
        </div>

        <h1 className="mt-3 text-gray-500 dark:text-gray-300">
          Sign in to access your account
        </h1>
      </div>

      <div className="mt-8">
        {errors
          ? errors.map((error: string) => <Alert key={error}> {error}</Alert>)
          : ""}
        <form>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
            >
              Email Address
            </label>
            <input
              value={email}
              onChange={updateEmail}
              autoComplete="email"
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mt-6">
            <div className="flex justify-between mb-2">
              <label
                htmlFor="password"
                className="text-sm text-gray-600 dark:text-gray-200"
              >
                Password
              </label>
              <a
                href="/reset"
                className="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline"
              >
                Forgot password?
              </a>
            </div>

            <input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={updatePassword}
              placeholder="Your Password"
              autoComplete="current-password"
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mt-6">
            <button
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              onClick={authorise}
              disabled={!canSubmit()}
            >
              Sign in
            </button>
          </div>
        </form>

        <p className="mt-6 text-sm text-center text-gray-400">
          Don&#x27;t have an account yet?{" "}
          <a
            href="/register"
            className="text-blue-500 focus:outline-none focus:underline hover:underline"
          >
            Sign up
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default Login;
