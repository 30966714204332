import { state as appState, updateAppState } from "@/store/app";
import { getSites, state as siteState, updateSiteState } from "@/store/sites";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSnapshot } from "valtio";

function DashboardSiteSelector() {
  const { siteSelectorOpen, isMobile } = useSnapshot(appState);
  const { sites, fetchedSites, activeSite } = useSnapshot(siteState);
  const currentSite = () => sites?.filter((s) => s.UUID === activeSite)[0];

  function toggleSiteSelector() {
    updateAppState({ siteSelectorOpen: !siteSelectorOpen });
  }
  useEffect(() => {
    if (!fetchedSites) getSites();
  }, [fetchedSites]);
  return (
    <div className="relative inline-block mx-3 md:mx-0" id="siteSelector">
      <button
        className={`w-full flex align-center justify-between items-center text-left leading-tight relative z-10 block p-2 text-gray-700 bg-white border border-transparent dark:text-white focus:border-blue-500 focus:ring-opacity-40 dark:focus:ring-opacity-40 focus:ring-blue-300 dark:focus:ring-blue-400 focus:ring dark:bg-gray-800 focus:outline-none hover:bg-gray-300 dark:hover:bg-gray-900 ${
          siteSelectorOpen ? "bg-gray-300 dark:bg-gray-900" : ""
        }`}
        onClick={toggleSiteSelector}
      >
        <div>
          <span className="text-gray-500 text-xs block">Current Site:</span>
          {currentSite() ? currentSite()?.Domain : ""}
        </div>
        <svg
          className="w-5 h-5 text-gray-600 dark:text-gray-500 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <div
        className={`w-full left-0 z-20 md:w-56 py-2 mt-2  rounded-md md:shadow-xl ${
          siteSelectorOpen ? "" : "hidden"
        }
          ${isMobile ? "" : "bg-white absolute"}`}
      >
        {sites
          ? sites.map((s) => (
              <div
                className={`justify-between flex items-stretch border-t hover:bg-gray-800 ${
                  s.ID === currentSite()!.ID
                    ? "bg-blue-200 md:hover:bg-blue-200"
                    : "md:hover:bg-gray-100"
                }`}
                key={s.ID}
              >
                <button
                  className={`text-left  w-full px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform hover:bg-gray-900 ${
                    s.ID === currentSite()!.ID
                      ? "md:hover:bg-blue-300"
                      : "md:hover:bg-gray-200"
                  }`}
                  onClick={() => updateSiteState({ activeSite: s.UUID })}
                >
                  <span>
                    <span className="text-gray-500 text-xs block">
                      {s.Title}
                    </span>
                    {s.Domain.toLowerCase()}
                  </span>
                </button>
                <Link
                  to={`/sites/${s.UUID}`}
                  className={`px-2 py-3 hover:bg-gray-900 flex items-center ${
                    s.ID === currentSite()!.ID
                      ? "md:hover:bg-blue-300"
                      : "md:hover:bg-gray-200"
                  }`}
                >
                  <svg
                    className="w-5 h-5 text-gray-600 "
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="sr-only">Settings</span>
                </Link>
              </div>
            ))
          : ""}

        <hr className="border-gray-900 md:border-gray-200" />

        <a
          href="/#"
          className="flex items-center px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform hover:bg-gray-900 md:hover:bg-gray-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 h-3 mr-2 text-gray-600"
            viewBox="0 0 448 512"
            fill="currentColor"
          >
            <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
          </svg>
          Create Site
        </a>
      </div>
    </div>
  );
}

export default DashboardSiteSelector;
