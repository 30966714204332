import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isAuthorised } from "../store/auth";
const isauth = () => {
  return isAuthorised();
};

export function PrivateRoute({ children }: { children: JSX.Element }) {
  let auth = isauth();
  let location = useLocation();

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export function AuthRoute({ children }: { children: JSX.Element }) {
  let auth = isauth();

  if (auth) {
    // Redirect them to the / page
    return <Navigate to="/" />;
  }

  return children;
}
