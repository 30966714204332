import { proxy, subscribe } from "valtio";
import { subscribeKey } from "valtio/utils";

type AuthState = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  authorised?: boolean;
  token?: string | null;
  errors?: string[];
};
const storedStateString = localStorage.getItem("authState");
const defaultState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  authorised: false,
  token: null,
  errors: [],
};
const initialState = storedStateString ? JSON.parse(storedStateString) : {};

export const state = proxy(Object.assign(defaultState, initialState));
export const token = () => (state.token ? `Bearer ${state.token}` : null);

subscribe(state, () => {
  localStorage.setItem("authState", JSON.stringify(state));
});
export const watchToken = (callback: any) =>
  subscribeKey(state, "token", (v) => callback(v));

export function updateAuthState(auth: AuthState) {
  Object.assign(state, auth);
}

export function login() {
  return new Promise((resolve) => {
    return fetch("http://localhost:8001/v1/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: state.email, password: state.password }),
    })
      .then((response) => response.json())
      .then((data) => {
        // success - lets store the token and clear the password set in state
        if (!data.Data) {
          updateAuthState({
            authorised: false,
            token: null,
            errors: [data.Message],
          });
          resolve("Login Failed");
        } else {
          updateAuthState({ authorised: true, token: data.Data, password: "" });
          resolve("Success");
        }
      })
      .catch((err) => {
        updateAuthState({
          authorised: false,
          token: null,
          errors: ["Email or Password does not match stored credentials"],
        });
        resolve("Login Failed");
      });
  });
}

export function register() {
  return new Promise((resolve) => {
    return fetch("http://localhost:8001/v1/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: state.email,
        password: state.password,
        firstName: state.firstName,
        lastName: state.lastName,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // success - lets store the token and clear the password set in state
        if (!data.Data) {
          updateAuthState({
            authorised: false,
            token: null,
            errors: [data.Message],
          });
          resolve("Registration Failed");
        } else {
          updateAuthState({ authorised: true, token: data.Data, password: "" });
          resolve("Success");
        }
      })
      .catch((err) => {
        updateAuthState({
          authorised: false,
          token: null,
          errors: ["Email or Password does not match stored credentials"],
        });
        resolve("Login Failed");
      });
  });
}

export function reset() {
  return new Promise((resolve) => {
    return fetch("http://localhost:8001/v1/forgotten", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: state.email,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        resolve("Successfully requested a password reset");
      })
      .catch(() => {
        resolve("Reset Failed");
      });
  });
}

export function resetPassword(token: string) {
  return new Promise((resolve) => {
    return fetch("http://localhost:8001/v1/reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: state.password,
        token: token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data.Message);
      })
      .catch(() => {
        resolve("Reset Failed");
      });
  });
}
export function isAuthorised() {
  return state.token !== null;
}
