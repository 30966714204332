import axios, { AxiosInstance } from "axios";
import { state as authState, token } from "@/store/auth";

const instances = {
  authorisedRequest: null,
  publicRequest: null,
} as { [key: string]: null | AxiosInstance };

Object.keys(instances).forEach((http) => {
  instances[http] = axios.create({
    baseURL: "http://localhost:8001/v1/",
    timeout: 1000,
  });
});
if (instances.authorisedRequest) {
  instances.authorisedRequest!.interceptors.request.use(
    function (request) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (token()) {
        if (!request.headers) {
          request.headers = {};
        }
        request.headers.Authorization = token()!;
      }
      return request;
    },
    function (error) {
      if (error.response.status === 401) {
        authState.token = null;
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  // Add a response interceptor
  instances.authorisedRequest!.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        authState.token = null;
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
}

export { instances };
