import { Outlet } from "react-router-dom";
import Sites from "../components/dashboard/Sites";
import { PrivateRoute } from "../RouterLayout";
import SitesOverview from "@/routes/components/dashboard/sites/SitesOverview";
import SitesSettings from "@/routes/components/dashboard/sites/SitesSettings";
import SitesTokens from "@/routes/components/dashboard/sites/SitesTokens";
import SitesUsers from "@/routes/components/dashboard/sites/SitesUsers";
const siteRoutes = [
  {
    path: "/sites",
    title: "Site",
    protected: true,
    inNav: false,
    element: (
      <PrivateRoute>
        <Sites>
          <Outlet />
        </Sites>
      </PrivateRoute>
    ),
    inGroupNav: true,
    parent: "sites",
    children: [
      {
        path: ":id",
        element: <SitesOverview />,
        title: "Overview",
        inNav: true,
      },
      {
        path: ":id/tokens",
        element: <SitesTokens />,
        title: "Tokens",
        inNav: true,
      },
      {
        path: ":id/users",
        element: <SitesUsers />,
        title: "Users",
        inNav: true,
      },
      {
        path: ":id/settings",
        element: <SitesSettings />,
        title: "Settings",
        inNav: true,
      },
    ],
  },
];

export default siteRoutes;
