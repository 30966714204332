import LogoCompact from "../../../library/branding/LogoCompact";
import { useLocation } from "react-router-dom";
import routes from "@/routes/routes";
import { state as appState, updateAppState } from "@/store/app";
import DashboardSiteSelector from "./dashboard/SiteSelector";
import { useSnapshot } from "valtio";
import { Link } from "react-router-dom";

type DashboardHeaderProps = {
  children?: string | Element | JSX.Element;
};
function DashboardHeader({ children }: DashboardHeaderProps) {
  const location = useLocation();
  const { menuOpen, isMobile } = useSnapshot(appState);
  function toggleMenu() {
    updateAppState({ menuOpen: !menuOpen });
  }

  return (
    <header className="sticky top-0 right-0 left-0 z-10">
      <nav className="bg-white shadow dark:bg-gray-800 py-2 md:py-0">
        <div className="px-6 mx-auto">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex items-center justify-between">
              <div className="text-xl font-semibold text-gray-700">
                <Link
                  className="text-2xl font-bold text-gray-800 transition-colors duration-200 transform dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"
                  to="/"
                >
                  <LogoCompact />
                </Link>
              </div>

              <div className="flex md:hidden">
                <button
                  type="button"
                  className="w-full text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
                  aria-label="toggle menu"
                  onClick={toggleMenu}
                >
                  <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                    <path
                      fillRule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <div
              className={`flex-1 md:flex md:items-center md:justify-between ${
                !isMobile || menuOpen ? "" : "hidden"
              }`}
            >
              <div className="flex flex-col -mx-4 md:flex-row md:items-center md:mx-8">
                <DashboardSiteSelector />

                {routes
                  .filter((r) => r.inNav === true)
                  .map((route) => (
                    <Link
                      key={route.path}
                      to={route.path}
                      className={`px-3 py-2 mx-2 mt-2 text-sm font-medium text-gray-700 transition-colors duration-200 transform rounded-md md:mt-0 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-900 ${
                        location.pathname.includes(route.path)
                          ? "bg-gray-300 dark:bg-gray-900"
                          : ""
                      }`}
                    >
                      {route.title}
                    </Link>
                  ))}
              </div>

              <div className="flex items-center mt-4 md:mt-0">
                <button
                  className="hidden mx-4 text-gray-600 transition-colors duration-200 transform md:block dark:text-gray-200 hover:text-gray-700 dark:hover:text-gray-400 focus:text-gray-700 dark:focus:text-gray-400 focus:outline-none"
                  aria-label="show notifications"
                >
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <button
                  type="button"
                  className="flex items-center focus:outline-none"
                  aria-label="toggle profile dropdown"
                >
                  <div className="w-8 h-8 overflow-hidden border-2 border-gray-400 rounded-full">
                    <img
                      src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                      className="object-cover w-full h-full"
                      alt="avatar"
                    />
                  </div>

                  <h3 className="mx-2 text-sm font-medium text-gray-700 dark:text-gray-200 md:hidden">
                    Khatab wedaa
                  </h3>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {children}
    </header>
  );
}

export default DashboardHeader;
