import DashboardLayout from "@/layouts/Dashboard";
import { Link, useLocation, useParams } from "react-router-dom";
import sites from "@/routes/routes/sites";
import { getSite } from "@/store/sites";
import { useEffect } from "react";
function Sites({ children }: any) {
  const { id } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    getSite(id!);
  }, [id]);

  return (
    <DashboardLayout
      navigation={
        <>
          {sites[0].children.map((t) => (
            <Link
              key={`siteTab-${t.path}`}
              className={`px-3 py-2 mx-2 mt-2 text-sm font-medium text-gray-700 transition-colors duration-200 transform rounded-md md:mt-0 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-800 ${
                (t.path === "" && pathname === id) ||
                pathname === t.path.replace(":id", `/sites/${id}`)
                  ? "bg-gray-300 dark:bg-gray-800"
                  : ""
              }`}
              to={t.path.replace(":id", id!)}
            >
              {t.title}
            </Link>
          ))}
          <div className="flex border-b border-gray-200 dark:border-gray-700"></div>
        </>
      }
    >
      <>{children}</>
    </DashboardLayout>
  );
}

export default Sites;
