import AuthLayout from "@/layouts/Auth";
import DashboardLayout from "@/layouts/Dashboard";
import DashboardHome from "./components/Home";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Reset from "./components/auth/Reset";
import ResetPassword from "./components/auth/ResetPassword";
import { AuthRoute, PrivateRoute } from "./RouterLayout";
import Events from "./components/dashboard/Events";
import Reports from "./components/dashboard/Reports";
import ReportsNavigation from "./components/dashboard/ReportsNavigation";
import sites from "./routes/sites";

type SiteRoutes = {
  path: string;
  element?: JSX.Element | JSX.Element[] | Element | Element[];
  title?: string;
  children?: SiteRoutes[];
  protected?: boolean;
  inNav?: boolean;
  inGroupNav?: boolean;
  parent?: string;
};

const routes: SiteRoutes[] = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <DashboardLayout navigation={<div>something something</div>}>
          <DashboardHome>Something New</DashboardHome>
        </DashboardLayout>
      </PrivateRoute>
    ),
    title: "Dashboard",
    protected: true,
    inNav: false,
  },
  {
    path: "/reports",
    element: (
      <PrivateRoute>
        <DashboardLayout navigation={<ReportsNavigation />}>
          <Reports>Reports are coming</Reports>
        </DashboardLayout>
      </PrivateRoute>
    ),
    protected: true,
    title: "Reports",
    inNav: true,
  },
  {
    path: "/reports/page-views",
    element: (
      <PrivateRoute>
        <DashboardLayout navigation={<ReportsNavigation />}>
          <Reports>Page View Reports are coming</Reports>
        </DashboardLayout>
      </PrivateRoute>
    ),
    protected: true,
    title: "Page Views",
    inNav: false,
    inGroupNav: true,
    parent: "reports",
  },
  {
    path: "/reports/heat-maps",
    element: (
      <PrivateRoute>
        <DashboardLayout navigation={<ReportsNavigation />}>
          <Reports>Heat Map Reports are coming</Reports>
        </DashboardLayout>
      </PrivateRoute>
    ),
    protected: true,
    title: "Heat Maps",
    inNav: false,
    inGroupNav: true,
    parent: "reports",
  },
  {
    path: "/analyse",
    element: (
      <PrivateRoute>
        <DashboardLayout navigation={<div>something something</div>}>
          <Reports>Analyse is coming</Reports>
        </DashboardLayout>
      </PrivateRoute>
    ),
    protected: true,
    title: "Analyse",
    inNav: true,
  },
  {
    path: "/events",
    element: (
      <PrivateRoute>
        <DashboardLayout navigation={<div>something something</div>}>
          <Events>Events are coming</Events>
        </DashboardLayout>
      </PrivateRoute>
    ),
    protected: true,
    title: "Events",
    inNav: true,
  },
  {
    path: "/login",
    element: (
      <AuthRoute>
        <AuthLayout>
          <Login />
        </AuthLayout>
      </AuthRoute>
    ),
    protected: false,
  },
  {
    path: "/register",
    element: (
      <AuthRoute>
        <AuthLayout>
          <Register />
        </AuthLayout>
      </AuthRoute>
    ),
    protected: false,
  },
  {
    path: "/reset",
    element: (
      <AuthRoute>
        <AuthLayout>
          <Reset />
        </AuthLayout>
      </AuthRoute>
    ),
    protected: false,
  },
  {
    path: "/reset/:token",
    element: (
      <AuthRoute>
        <AuthLayout>
          <ResetPassword />
        </AuthLayout>
      </AuthRoute>
    ),
    protected: false,
  },
  ...sites,
];

export default routes;
