function LogoCompact() {
    return (
      <svg height="30" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.4064 6.83529L28.9254 19.3975L16.8548 34.8505C16.627 35.1421 16.1858 35.1421 15.9579 34.8505L0.123717 14.5793C0.0396053 14.4716 0.00185286 14.3497 0.00207483 14.2306C0.000701006 14.2144 0 14.1981 0 14.1816V9.25025C0 9.08255 0.072546 8.93179 0.18797 8.82766C0.206819 8.80138 0.228361 8.77601 0.252708 8.75182L8.89268 0.165409C9.1146 -0.0551365 9.47295 -0.0551362 9.69487 0.165409L16.4064 6.83529ZM19.3109 30.4602H13.5011L14.8105 32.0541H18.0037L19.3109 30.4602Z" fill="#FF9900"/>
      <path d="M17.5496 5.70398L23.0149 0.233077C23.2332 0.014618 23.5856 0.0146183 23.8039 0.233077L32.301 8.73826C32.325 8.76223 32.3462 8.78736 32.3647 8.81338C32.4782 8.91654 32.5496 9.06586 32.5496 9.23197V14.1167C32.5496 14.133 32.5489 14.1492 32.5475 14.1652C32.5477 14.2831 32.5106 14.404 32.4279 14.5106L29.8272 18.0692L17.5496 5.70398Z" fill="url(#paint0_linear_15_14)"/>
      <defs>
      <linearGradient id="paint0_linear_15_14" x1="30.4609" y1="2.44729" x2="24.9308" y2="7.81971" gradientUnits="userSpaceOnUse">
      <stop offset="0.0260417" stopColor="#FF9900"/>
      <stop offset="1" stopColor="#F19305"/>
      </linearGradient>
      </defs>
      </svg>
      )
}

export default LogoCompact