import "./App.css";
import routes from "./routes/routes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} {...route}>
            {route.children
              ? route.children.map((t) => <Route key={t.path} {...t} />)
              : ""}
          </Route>
        ))}
      </Routes>
    </Router>
  );
}

export default App;
