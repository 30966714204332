import { proxy } from "valtio";
import { instances } from "@/services/http";
const { authorisedRequest } = instances;

export type Site = {
  ID?: number;
  Domain: string;
  Title: string;
  UUID?: string;
};
type siteState = {
  activeSite?: string | undefined | null;
  editingSite?: Site | undefined | null;
  sites?: Site[];
  fetchedSites?: boolean;
  fetchingSite?: boolean;
};

export const state: siteState = proxy({
  sites: [] as Site[],
  fetchedSites: false,
  activeSite: null,
  editingSite: null,
  fetchingSite: false,
});

export function getSite(id: string | null) {
  if (state.fetchingSite === true) return;
  state.fetchingSite = true;
  authorisedRequest
    ?.request({
      url: "/sites/" + id,
      method: "GET",
      data: {
        id,
      },
    })
    .then(({ data }) => {
      if (!data.Data) {
        if (state.editingSite !== null) state.editingSite = null;
      } else {
        if (JSON.stringify(state.editingSite) !== JSON.stringify(data.Data))
          state.editingSite = data.Data;
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 403) {
        window.location.replace("/");
      }
    })
    .finally(() => {
      state.fetchingSite = false;
    });
}

export function deleteSite(id: string | null) {
  authorisedRequest
    ?.request({
      url: "/sites/" + id,
      method: "DELETE",
      data: {
        id,
      },
    })
    .then(({ data }) => {
      if (data.Data) {
        alert(data.Message);
        setTimeout(() => {
          window.location.replace("/");
        }, 200);
      }
    })
    .catch((err) => {
      alert(err);
    });
}

export function getSites() {
  authorisedRequest
    ?.get("sites")
    .then(({ data }) => {
      if (!data.Data) {
        if (!state.sites || state.sites.length > 0) state.sites = [];
        state.fetchedSites = true;
      } else {
        if (JSON.stringify(state.sites) !== JSON.stringify(data.Data))
          state.sites = data.Data;
        state.activeSite = data.Data[0].UUID;
        state.fetchedSites = true;
      }
    })
    .catch((err) => {
      console.log(err);
      state.fetchedSites = false;
    });
}

export function updateSite(site: Site) {
  let data = {} as any;
  if (site.Domain) data.Domain = site.Domain;
  if (site.Title) data.Title = site.Title;

  authorisedRequest
    ?.request({
      url: "/sites/" + site.UUID,
      method: "PUT",
      data,
    })
    .then(({ data }) => {
      if (data.Data) {
        getSites();
      }
    })
    .catch((err) => {
      alert(err);
    });
}

export function updateSiteState(stateUpdate: siteState) {
  Object.assign(state, stateUpdate);
}
