import { proxy } from "valtio";
import { subscribeKey } from "valtio/utils";

type AppState = {
  menuOpen?: boolean;
  siteSelectorOpen?: boolean;
  width?: number;
  isMobile?: boolean;
};

export const state = proxy({
  menuOpen: false,
  siteSelectorOpen: false,
  width: 1024,
  isMobile: false,
});

export function updateAppState(auth: AppState) {
  Object.assign(state, auth);
}

export function isMenuOpen() {
  return state.menuOpen !== null;
}

function handleWindowSizeChange() {
  state.width = window.innerWidth;
}
function handleWindowClick(ev: MouseEvent) {
  const el = ev.target as HTMLElement;
  const siteSelector = document.getElementById("siteSelector");
  if (ev.target !== siteSelector && !siteSelector?.contains(el)) {
    if (state.siteSelectorOpen) state.siteSelectorOpen = false;
  }
}

window.addEventListener("click", handleWindowClick);
window.addEventListener("resize", handleWindowSizeChange);
document.addEventListener("DOMContentLoaded", handleWindowSizeChange);

subscribeKey(state, "width", () => {
  state.isMobile = state.width <= 768;
});
