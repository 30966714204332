import SubNavigation from "@/library/navigation/SubNavigation";
import { isAuthorised, watchToken } from "@/store/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardFooter from "./components/dashboard/Footer";
import DashboardHeader from "./components/dashboard/Header";

type DashboardProps = {
  children?: JSX.Element;
  navigation?: JSX.Element | JSX.Element[];
  path?: string;
};
function DashboardLayout({ children, navigation }: DashboardProps) {
  const navigate = useNavigate();

  useEffect(() =>
    watchToken((v: any) => {
      if (!isAuthorised()) {
        // TODO: make this an overlay so the user stays where they are
        navigate("/login", {
          state: {
            redirect: window.location.href,
          },
        });
      }
    })
  );
  return (
    <div className="min-h-screen bg-gray-300 relative">
      <DashboardHeader>
        {navigation ? <SubNavigation>{navigation}</SubNavigation> : ""}
      </DashboardHeader>
      <div className="flex flex-row flex-grow min-h-screen">
        <section className="flex flex-col flex-grow overflow-y-auto">
          <main className="flex-grow relative p-4 px-6">{children}</main>
          <DashboardFooter>footer content</DashboardFooter>
        </section>
      </div>
    </div>
  );
}

export default DashboardLayout;
