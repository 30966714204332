import { useSnapshot } from "valtio";
import {
  state as sitesState,
  Site,
  deleteSite,
  updateSite as saveSiteChanges,
} from "@/store/sites";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
function SitesSettings() {
  const { editingSite } = useSnapshot(sitesState);
  const { id } = useParams();
  const [site, updateSite] = useState(editingSite as Site);
  const saveSettings = (ev: React.MouseEvent) => {
    ev.preventDefault();
    saveSiteChanges(site);
  };
  useEffect(() => {
    updateSite(editingSite as Site);
  }, [editingSite]);
  return (
    <>
      <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md">
        <h2 className="text-lg font-semibold text-gray-700 capitalize">
          Settings
        </h2>
        {site ? (
          <form>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label className="text-gray-700" htmlFor="title">
                  Title
                </label>
                <input
                  id="title"
                  type="text"
                  value={site.Title}
                  onChange={(ev) =>
                    updateSite({ ...site, Title: ev.target.value })
                  }
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                />
              </div>

              <div>
                <label className="text-gray-700" htmlFor="domain">
                  Domain
                </label>
                <input
                  id="domain"
                  type="text"
                  value={site.Domain}
                  onChange={(ev) =>
                    updateSite({ ...site, Domain: ev.target.value })
                  }
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40  focus:outline-none focus:ring"
                />
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button
                className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                onClick={saveSettings}
              >
                Save
              </button>
            </div>
          </form>
        ) : (
          "Loading..."
        )}
      </section>
      <section className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md mt-4">
        <h2 className="text-lg font-semibold text-gray-700 capitalize">
          Remove Site
        </h2>

        <button
          onClick={() => deleteSite(id!)}
          className="flex items-center px-2 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-red-600 rounded-md hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-80"
        >
          <svg
            className="w-4 h-4 mx-1 text-white opacity-75"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            fill="currentColor"
          >
            <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z" />
          </svg>

          <span className="mx-1">Delete Site</span>
        </button>
      </section>
    </>
  );
}

export default SitesSettings;
